import React, { Component } from 'react';
import Translate from '../../hocs/translate';
import { bindActionCreators } from 'redux';
import { focusNextInput } from '../../utils/inputs';
import { connect } from 'react-redux';
import { LANGUAGES } from '../../constants';
import { formatUSPhoneNumber } from '../../utils/phone_format';
import { backToEnterPhone } from '../../actions/simple_login';
import { verifyPincode } from '../../actions/signup';
import { validPassword } from '../../utils/validations';
import LoginFooter from '../../components/login_footer/';
import BackButton from '../../components/buttons/back_button';
import FormPassword from '../../components/forms/form_password';
import IconError from '../../components/assets/icon_error';

class NewLearnerComponent extends Component {
  constructor() {
    super();
    this.state = {
      password: '',
      confirmPassword: '',
      pincode: '',
      receiveSmsReminders: true,
      passwordError: false,
      pincodeSupplied: false,
      pincodeError: false,
      showRequestErrorMsg: null
    };
    this.setPassword = this.setPassword.bind(this);
    this.setConfirmPassword = this.setConfirmPassword.bind(this);
    this.pincodeHandler = this.pincodeHandler.bind(this);
    this.submitSignupForm = this.submitSignupForm.bind(this);
    this.smsReminderHandler = this.smsReminderHandler.bind(this);
    this.renderError = this.renderError.bind(this);
    this.renderSmsReminder = this.renderSmsReminder.bind(this);
    this.renderPincodeForm = this.renderPincodeForm.bind(this);
    this.renderFormDescription = this.renderFormDescription.bind(this);
    this.handleUpdateError = this.handleUpdateError.bind(this);
  }

  componentDidMount() {
    // Do not autofocus on KaiOS - WEBAPP-1959
    if (/KAIOS/.test(navigator.userAgent) === false) {
      var input = document.getElementsByClassName('form-input');
      if (input[0]) input[0].focus();
    }

    let pincode = window.localStorage.getItem('pincode');
    let pincodeSupplied = pincode !== undefined && pincode !== null && pincode !== '';
    this.setState({ pincodeSupplied });
    if (pincodeSupplied) {
      this.setState((prevState) => ({ ...prevState, pincode }));
    }

    document.title = 'Cell-Ed Sign Up • Essential Skills on the Go';
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.requestErrorMsg !== this.props.requestErrorMsg &&
      this.props.requestErrorMsg !== null
    ) {
      this.setState({
        showRequestErrorMsg: this.props.requestErrorMsg,
        pincodeSupplied: false
      });
    }

    if (prevProps.verifyPincodeSuccess !== this.props.verifyPincodeSuccess) {
      this.setState({ ...this.state, pincodeSupplied: this.props.verifyPincodeSuccess });
    }

    if (prevProps.verifyPincodeFailed !== this.props.verifyPincodeFailed) {
      this.setState({ ...this.state, pincodeError: this.props.verifyPincodeFailed });
    }
  }

  setPassword(value) {
    this.setState({ ...this.state, password: value }, () => {
      if (this.state.passwordError) {
        // if in state of error, validate password updates and remove error message once user satisfies all password criteria
        if (validPassword(this.state.password, this.state.confirmPassword)) {
          this.setState({ ...this.state, pincodeError: true });
        }
      }
    });
  }

  setConfirmPassword(value) {
    this.setState({ ...this.state, confirmPassword: value }, () => {
      if (this.state.passwordError) {
        // if in state of error, validate password updates and remove error message once user satisfies all password criteria
        if (validPassword(this.state.password, this.state.confirmPassword)) {
          this.setState({ ...this.state, passwordError: false });
        }
      }
    });
  }

  handleUpdateError(payload) {
    this.setState({ ...this.state, passwordError: payload });
  }

  pincodeHandler(e) {
    this.setState({
      pincodeError: false,
      pincode: this.trimPincode(e.target.value),
      showRequestErrorMsg: null
    });
  }

  trimPincode(str) {
    return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
  }

  smsReminderHandler() {
    let toggle = !this.state.receiveSmsReminders;
    this.setState({ receiveSmsReminders: toggle });
  }

  submitSignupForm(e) {
    e.preventDefault();
    this.setState({
      passwordError: false,
      pincodeError: false
    });

    // PIN code flow
    if (this.state.pincodeSupplied === false) {
      if (this.state.pincode === '') {
        this.setState({ ...this.state, pincodeError: true });
      } else {
        // verify pincode
        this.props.verifyPincode({ pincode: this.state.pincode });
      }
    }

    // Password flow
    else {
      if (validPassword(this.state.password, this.state.confirmPassword)) {
        const credentials = {
          password: this.state.password,
          pincode: this.state.pincode,
          receiveSmsReminders: this.state.receiveSmsReminders
        };

        // submit signup password
        this.props.submitSignupForm(credentials);
      } else {
        this.setState({ ...this.state, passwordError: true });
      }
    }
  }

  renderError(type, requestErr) {
    const errorTxt = (() => {
      let txt;
      switch (type) {
        case 'pincode':
          if (this.state.pincode === '') {
            txt = LANGUAGES[this.props.currentLanguage]
              ? LANGUAGES[this.props.currentLanguage].Notifications['PIN code is required']
              : this.props.strings['PIN code is required'];
            break;
          }

          if (this.props.verifyPincodeFailed) {
            txt = this.props.strings['PIN code does not exist'];
          }
          break;
        default:
          txt = LANGUAGES[this.props.currentLanguage]
            ? LANGUAGES[this.props.currentLanguage].Notifications[requestErr]
            : this.props.strings[requestErr];
          break;
      }
      return txt;
    })();

    return (
      <div className="notificationStyles form-item">
        <IconError className="notificationStyles__icon" /> {errorTxt}
      </div>
    );
  }

  renderPincodeForm() {
    return (
      <div className="form-box__pin-code">
        <label htmlFor="pincode" className="visually-hidden">
          {this.props.strings['Enter your PIN Code']}
        </label>
        <div className="form-horizontal">
          <input
            className={`form-input ${this.state.pincodeError ? 'form-input--error' : ''}`}
            id="pincode"
            value={this.state.pincode}
            onChange={this.pincodeHandler}
            placeholder={this.props.strings['Enter your PIN code']}
            type="text"
            pattern="[0-9]*"
            onKeyDown={(e) => {
              if (e.key === 'Enter') focusNextInput(e);
            }}
            aria-label={this.props.strings['Enter your PIN code']}
          />
        </div>

        {this.state.pincodeError && this.renderError('pincode')}
        {this.state.showRequestErrorMsg &&
          this.renderError('request', this.state.showRequestErrorMsg)}
      </div>
    );
  }

  renderSmsReminder() {
    return (
      <div className="signup-check-box">
        <label className="signup-check-box__label container">
          <input
            id="allowSms"
            className="signup-check-box__input"
            type="checkbox"
            onChange={this.smsReminderHandler}
            checked={this.state.receiveSmsReminders}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                this.smsReminderHandler(e);
              }
            }}
          />
          <span className="signup-check-box__checkmark checkmark" />
          {this.props.strings['Allow my coach to send me messages']}
        </label>
      </div>
    );
  }

  renderFormDescription() {
    if (this.state.pincodeSupplied === false) {
      return this.props.strings[
        'Enter your 4+ digit PIN code. This code comes from your organization.'
      ];
    }
    return this.props.strings['Create a new password. It needs to be 6 characters or more.'];
  }

  render() {
    return (
      <div className="app-full-h">
        <form className="onboarding-form" onSubmit={this.submitSignupForm}>
          <div className="login-page-header">
            <BackButton onClick={this.props.backToEnterPhone} />
          </div>
          <div className="form-title">
            <span>{this.props.strings['Create your profile']}</span>
          </div>
          <div className="form-subtitle">{formatUSPhoneNumber(this.props.phoneNumber)}</div>

          <p className="form-description">{this.renderFormDescription()}</p>

          {this.state.pincodeSupplied === false ? (
            this.renderPincodeForm()
          ) : (
            <FormPassword
              focusNextInput={focusNextInput}
              setPassword={this.setPassword}
              setConfirmPassword={this.setConfirmPassword}
              passwordError={this.state.passwordError}
              handleUpdateError={this.handleUpdateError}
              password={this.state.password}
              confirmPassword={this.state.confirmPassword}
              handleEnterSubmit={this.submitSignupForm}
            />
          )}

          {this.props.waitingResponse && (
            <p style={{ textAlign: 'center' }}>{this.props.strings['Requesting access']}</p>
          )}

          {this.state.pincodeSupplied === false && this.renderSmsReminder()}

          <div className="form-gap" />
          <div className="onboarding-form__footer">
            <div className="form-button-view">
              <button
                className="button form-button form-item box-sizing-border-box"
                type="submit"
                aria-label="Continue Button">
                {this.props.strings['Continue']}
              </button>
            </div>

            {this.state.pincodeSupplied === false && (
              <>
                <p className="form-terms">
                  {this.props.strings['By clicking Continue, you agree to our ']}
                  {/* <a
                    href="https://www.cell-ed.com/terms-of-service"
                    target="_blank"
                    className="no-line-break"
                    rel="noreferrer">
                    <span>{this.props.strings['Terms of Service']}</span>
                  </a> */}
                  <a
                    className="faqs__link"
                    href="https://www.cell-ed.com/terms-of-service"
                    target="_blank"
                    rel="noreferrer">
                    {this.props.strings['Terms of Service']}
                  </a>
                  {" and "}
                  <a
                    className="faqs__link"
                    href="https://www.cell-ed.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer">
                    {this.props.strings['Privacy Policy']}
                  </a>
                </p>
                <LoginFooter />
              </>
            )}
          </div>
        </form>
      </div>
    );
  }
}

NewLearnerComponent.defaultProps = {
  strings: {
    'Create your profile': 'Create your profile',
    'Create a password': 'Create a password',
    'Confirm password': 'Confirm password',
    'Enter your PIN code': 'Enter your PIN code',
    Continue: 'Continue',
    'Allow my coach to send me messages': 'Allow my coach to send me messages',
    'By clicking Continue, you agree to our ': 'By clicking Continue, you agree to our ',
    'Terms of Service': 'Terms of Service',
    'Privacy Policy': 'Privacy Policy',
    'Please fill in all fields': 'Please fill in all fields',
    'Create a new password. It needs to be 6 characters or more.':
      'Create a new password. It needs to be 6 characters or more.',
    'Contact your organization if you did not receive one.':
      'Contact your organization if you did not receive one.',
    'Requesting access': 'Requesting access',
    'Password is required': 'Password is required',
    'Password must be at least 6 letters and/or numbers':
      'Password must be at least 6 letters and/or numbers',
    'PIN code is required': 'PIN code is required',
    'PIN code does not exist': 'PIN code does not exist',
    'El código PIN suministrado no existe': 'El código PIN suministrado no existe',
    'Enter your 4+ digit PIN code. This code comes from your organization.':
      'Enter your 4+ digit PIN code. This code comes from your organization.',
    Step: 'Step',
    of: 'of',
    show: 'show',
    hide: 'hide',
    password: 'password',
    'Passwords do not match': 'Passwords do not match'
  }
};

const mapStateToProps = (state) => {
  return {
    requestErrorMsg:
      state.notifications.notifications.length &&
        state.notifications.notifications[0].avoidUINotification
        ? state.notifications.notifications[0].message
        : null,
    currentLanguage: state.i18n.language,
    verifyPincodeFailed: state.signup.verifyPincodeFailed,
    verifyPincodeSuccess: state.signup.verifyPincodeSuccess
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ backToEnterPhone, verifyPincode }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Translate('NewLearnerComponent')(NewLearnerComponent));
