import React from 'react';

export default {
  languageName: 'español',
  NavBar: {
    'HELLO FROM CELL-ED!': 'HOLA DESDE CELL-ED!',
    HOME: 'Inicio',
    HELP: 'Ayuda',
    ME: 'Yo'
  },

  HomePage: {
    'NEW LEARNER': 'NUEVO ESTUDIANTE',
    'SIGN IN': 'INICIAR SESIÓN'
  },

  Chat: {
    questionnaireConfirmation: '¿Estás seguro de que quieres seleccionar?',
    Yes: 'Sí'
  },
  UserInput: {
    SEND: 'ENVIAR',
    RECORD: 'GRABAR',
    STOP: 'PARAR',
    WAIT_TO_TYPE: 'Espera para responder',
    'Wait to type your response': 'Espera para tipear tu respuesta',
    'enter your response now': 'escribe tu respuesta',
    'Select an option from above': 'Elige una de las opciones de arriba',
    'Enter your phone number': 'Ingresa tu teléfono',
    'Wait to record': 'Espera para grabar',
    'Click RECORD to start': 'Presiona GRABAR para comenzar',
    'Click STOP to finish': 'Presiona DETENER para terminar',
    'Loading...': 'Cargando...'
  },

  MultipleChoiceInput: {
    'Tap on any of the options below': 'Elige alguna de las siguientes opciones',
    'Click below to continue': 'Presiona abajo para continuar',
    'Select an option below': 'Selecciona una opción debajo '
  },

  NotFound: {
    'Page not found': 'Página no encontrada'
  },

  BackButton: {
    Back: 'Atrás'
  },

  SignupForm: {
    'By clicking Continue, you agree to our ': 'Haciendo clic en Continuar aceptas nuestros ',
    'Terms of Service': 'Térmimos y Condiciones',
    'Please fill in all fields': 'Llena todos los campos por favor',
    'Password should be 6 characters or more.': 'La contraseña debe tener 6 letras y/o números',
    'Phone Number': 'Teléfono',
    Password: 'Crear nueva contraseña',
    'PIN Code': 'Código PIN',
    'Allow my coach to send me messages': 'Permito recibir mensajes de mi instructor',
    'Agree to Terms of Service': 'Aceptar terminos y condiciones',
    Continue: 'Continuar',
    'New Learner': 'Nuevo Estudiante',
    'Determine password rules': 'La constraseña debe tener al menos 6 caracteres.',
    'The PIN code is a 4+ digit code.': 'El código de PIN es un número de 4+ dígitos.',
    'Contact your organization if you did not receive one.':
      'Consulta con tu organización si no lo has recibido.',
    'Enter your phone number': 'Ingresa tu teléfono'
  },

  SignupConfirmationForm: {
    Verify: 'Verificar',
    'Learner created': 'Cuenta creada',
    'Confirmation code sent': 'Código de confirmación enviado'
  },

  Login: {
    'Please, fill in all fields': 'Llena todos los campos por favor',
    'Cell Phone': 'Teléfono Celular',
    Password: 'Contraseña',
    GO: 'IR',
    'Sign In': 'Iniciar Sesión',
    'NEW LEARNER?': '¿NUEVO ESTUDIANTE?',
    'Forgot password?': '¿Olvidaste tu contraseña?',
    'Enter your phone number': 'Ingresa tu teléfono'
  },

  EasyLoginComponent: {
    'Phone Number': 'Número de Teléfono',
    'Please, enter your cell phone number': 'Por favor, ingresa tu número de celular',
    'Go!': '¡Ir!',
    'Allow my coach to send me messages': 'Permito recibir mensajes de mi instructor',
    'By clicking Go!, you agree to our ': 'Haciendo clic en Ir aceptas nuestros ',
    'Terms of Service': 'Términos y condiciones',
    'Please accept the Terms of Service': 'Por favor acepta los términos y condiciones',
    'Please, enter your phone number': 'Por favor, ingresa tu número de celular',
    'Requesting access': 'Accediendo',
    'Enter your phone number': 'Ingresa tu teléfono'
  },

  Demo: {
    'Enable Survey': 'Realizar Encuesta',
    'Enable Assessment': 'Realizar Evaluación'
  },

  SignupConfirm: {
    'Create your profile': 'Configura tu perfil',
    'Confirmation in progress...': 'Confirmación en progreso...',
    'Check your text message. We just sent you a 5-digit verification code to the number listed above.':
      'Te enviamos un mensaje de texto con un código de verificación temporal de 5 dígitos.',
    'We just sent the text code to:': 'Te acabamos de enviar el código al:',
    'Phone number:': 'Número de teléfono:',
    'I need a new code': 'Necesito un código nuevo',
    'Enter a different number': 'Usar un número de teléfono diferente',
    Step: 'Paso',
    of: 'de'
  },

  ActiveCourse: {
    'Show previous messages': 'Ver mensajes anteriores',
    'Your sessions has expired': 'Tu sesión ha expirado',
    'Please sign in again': 'Por favor vuelve a iniciar sesión',
    'There was a problem loading the course': 'Hubo un problema al cargar el curso',
    'Please refresh the page': 'Vuelve a cargar la página por favor',
    MESSAGES: 'MENSAJES',
    'Loading...': 'Cargando...',
    Back: 'Volver',
    'Go Back': 'Regresa a la pantalla anterior',
    'Close Search Results': 'Cerrar los Resultados de la Búsqueda',
    // WalkThrough
    'Questions? Just click HELP.': '¿Preguntas? Solo haz clic en ayuda.',
    'When you need to answer, text here.': 'Cuando necesites responder, hazlo aquí.',
    'Press PLAY anytime to start the course.':
      'Presiona PLAY en cualquier momento para empezar el curso.',
    'Join Cell-Ed GROUPS to connect with other Cell-Ed learners.':
      'Únete a los grupos de Cell-Ed para conectarte con otros estudiantes',
    'Got it!': 'Entendido!',
    Next: 'Siguiente',
    Close: 'Cerrar',
    'Welcome! To start, please press the play button':
      '¡Bienvenido! Haz clic en el botón play para empezar',
    'To start, click the PLAY button. Please use headphones or the speaker function.':
      'Para comenzar, haz clic en el botón PLAY. Por favor utiliza audífonos o la función de altavoz ahora.'
  },

  Me: {
    'CHECK YOUR PROGRESS!': '¡MIRA TU PROGRESO!',
    Courses: 'Cursos',
    Resources: 'Recursos',
    Preferences: 'Preferencias',
    Achievements: 'Logros Obtenidos',
    'Log in information': 'Información de sesión',
    'Live Coach': 'Live Coach',
    'Enter word(s)': 'Ingresa el texto a buscar',
    Back: 'Volver',
    Close: 'Cerrar',
    'Go Back': 'Regresar a la pantalla anterior',
    'Close Search Results': 'Cerrar los Resultados de la Búsqueda',
    LOGOUT: 'Cerrar sesión'
  },

  Achievements: {
    total: 'total',
    'this week': 'Esta Semana',
    'All Time': 'Todo el Tiempo'
  },

  AchievementsList: {
    'There are no courses completed yet. Continue studying!':
      'Todavía no se ha completado un curso. ¡Continúa estudiando!'
  },

  PushRegistrationComponent: {
    'Push Notifications': 'Notificaciones de alerta push',
    PUSH_MESSAGE:
      'Cell-ed utiliza notificaciones de alerta push para enviar mensajes de tu coach e instructor. Haz click en el botón PERMITIR para comenzar!',
    Cancel: 'Cancelar',
    'Enable 🎉': 'PERMITIR 🎉'
  },

  Wizenoze: {
    'Word to search': 'Palabra(s) a buscar',
    Search: 'Buscar',
    'Level 1': 'Nivel 1',
    'Level 2': 'Nivel 2',
    'Level 3': 'Nivel 3',
    'Level 4': 'Nivel 4',
    'Level 5': 'Nivel 5',
    Close: 'Cerrar'
  },
  Resources: {
    'No resources available.': 'No hay recursos disponibles.',
    'Search on the Internet': 'Buscar en Internet'
  },

  Help: {
    'HELP PAGE': 'CONTACTAR A TU MAESTRA',
    'Email my Coach': 'Correo Electrónico',
    Questions: 'Preguntas',
    'Text or Call my Coach': 'Texto / Llamada',
    'Contact my Coach': 'Contactar a mi Coach',
    'How can Cell-Ed help you?': '¿Cómo te puede ayudar Cell-Ed?'
  },

  LiveCoach: {
    'If you have any questions, you can contact your coach':
      'Si tienes alguna pregunta, contacta a tu coach',
    at: 'al',
    'Your coach is': 'Tu coach es',
    'Welcome!': '¡Bienvenido/a!'
  },

  ContactUs: {
    'Thank you. A coach will help you as soon as possible':
      'Gracias. Un coach lo ayudará lo más pronto posible.',
    'Send us a message': 'Envíanos un mensaje',
    'Enter your message': 'Ingresa tu mensaje',
    'Send Message': 'Enviar el mensaje',
    'Type your message in the box below. Your coach will respond in the next 24 hours.':
      'Escribe tu mensaje en el cuadro de abajo. Tu coach te responderá en las siguientes 24 horas.'
  },

  Faqs: {
    question1: '¿Cómo comienzo mi curso?',
    question2: '¿Cómo veo mis certificados?',
    question5: '',
    answer1: <p>Navega a la página de inicio y haz clic en el botón de play.</p>,
    answer2: (
      <p>
        Necesitas completar un curso para ver un certificado. Navega a la página YO y haz clic en
        Cursos. Los cursos que terminaste tienen la palabra “Acabado” al lado. Presiona en el curso
        que completaste. Deberías ver el certificado en la pantalla.
      </p>
    ),
    answer5: '',
    'Need more help?': '¿Necesitas más ayuda?',
    'Please click': 'Por favor haz clic',
    HERE: 'AQUI',
    'to send us your question.': 'para enviarnos tu pregunta.'
  },

  Profile: {
    'Change Language': 'Cambiar Idioma',
    'Allow my coach to send me messages': 'Permito recibir mensajes de mi instructor',
    'Display Right-to-Left': 'Mostrar de Derecha a Izquierda',
    LOGOUT: 'Cerrar sesión',
    'Your phone number is not a valid number, your coach will not be able to send you messages.':
      'Tu número de teléfono no es válido, Tu coach no podrá enviarle mensajes.',
    'Privacy Policy': 'Políticas de Privacidad',
    'Terms of Service': 'Términos de Servicio',
    'About Cell-Ed': 'Sobre Cell-Ed',
    'App version': 'Versión de Aplicación'
  },

  Notifications: {
    // signin
    'Phone not confirmed': 'Teléfono no confirmado',
    'Invalid credentials': 'Teléfono o contraseña incorrecta',
    'Account does not exist': 'La cuenta no existe',

    // signup
    'Learner exists': 'Usuario ya existe',
    'Confirmation code sent': 'El código de confirmación se ha enviado',
    'Seats exceeded': 'No quedan más lugares disponibles',
    'PIN code does not exist': 'El código PIN suministrado no existe',
    'PIN code is required': 'El código PIN es requerido',
    'This PIN code is not active at this time.': 'Este PIN code no está activo en este momento.',
    'Learner created': 'Usuario creado',
    'Database Results Error': 'Error en la base de datos',
    'Account successfully created': 'Cuenta creada correctamente',
    'A new confirmation code was sent': 'Se te ha enviado un nuevo código de confirmación',
    'The account you are trying to access is currently offline.':
      'La cuenta a la que intentas acceder está desconectada',

    // confirmation code
    'Verified confirmation code': 'Código validado',
    'Invalid confirmation code': 'Código incorrecto',

    // resend confirmation code
    'PhoneNumber field is required': 'Todos los campos son requeridos',
    'Learner does not exist': 'Teléfono no confirmado',
    'Confirmed a previous code': 'Teléfono o contraseña incorrecta',
    'Attempts exceeded': 'El teléfono no existe',
    'Confirmed code sent': 'Código reenviado',

    // main course
    'Connection problem': 'Problemas de conexión',

    // email submit

    'Email sent': 'Mensaje enviado',
    'Error: email could not be sent': 'Error: el mensaje no se ha podido enviar',

    // course details

    "Course details couldn't be load": 'Los detalles del curso no pudieron cargarse',

    // preferences

    'Preferences successfully changed': 'Cambio realizado exitosamente',

    // configuration
    "Language couldn't be set in server": 'El lenguaje no pudo ser actualizado',

    'Only mobile phone numbers are allowed': 'Unicamente son permitidos teléfonos móviles',
    'Please tell us your phone number': 'Por favor indíquanos tu número de teléfono móvil',
    'No learner ID found. LiteracyNow app must be installed.':
      'ID de usuario no encontrado. Por favor instala la app LiteracyNow',
    "The supplied phone number doesn't seem to be valid":
      'El teléfono ingresado no parece ser válido.',
    // eslint-disable-next-line no-dupe-keys
    'PIN code does not exist': 'El código PIN suministrado no existe',
    'The supplied phone number cannot be used with this device':
      'El número de teléfono suministrado no puede ser utilizado en este dispositivo'
  },

  TimeOutModal: {
    'ARE YOU STILL HERE?': '¿ESTÁS AQUÍ TODAVÍA?',
    'Yes!': '¡Si!',
    'Log off in:': 'Cerrando sesión en:',
    'Your session has expired': 'Tu sesión ha caducado',
    'Please login again': 'Por favor ingresa nuevamente'
  },
  CoursesElements: {
    'Active Course': 'Curso Activo',
    'NOT TAKEN': 'NO CURSADO',
    'In Progress': 'En Progreso',
    COMPLETED: 'ACABADO',
    VIEW: 'VER',
    'NO CERTIFICATE': 'SIN CERTIFICADO',
    REDO: 'REHACER',
    Continue: 'Continuar',
    START: 'COMENZAR',
    NEW: 'NUEVO',
    Courses: 'Cursos',
    Completed: 'Acabado',
    'Course in Progress': 'Curso en Progreso'
  },

  SwitchFlowModal: {
    OK: 'OK',
    'Cannot Switch Course': 'Antes de continuar debes completar las preguntas. Gracias.'
  },
  CoursesAttendancesElements: {
    'NOT TAKEN': 'NO CURSADO',
    'In Progress': 'En Progreso',
    Completed: 'Completado',
    'Course Completed!': '¡Curso terminado!',
    'VIEW CERTIFICATE': 'VER CERTIFICADO',
    'HIDE CERTIFICATE': 'OCULTAR CERTIFICADO',
    'NO CERTIFICATE': 'SIN CERTIFICADO',
    'View your certificate when you complete the course':
      'Mira tu certificado de finalización del curso'
  },
  CoursesList: {
    'Switch Course Continue': '¿Estás seguro que quieres continuar con?',
    'Switch Course Start': '¿Estás seguro de que quieres comenzar?',
    'Switch Course Redo': '¿Estás seguro de que quieres volver a hacer?',
    YES: 'Sí',
    NO: 'No'
  },
  CourseDetail: {
    START: 'COMENZAR',
    Continue: 'Continuar',
    'units completed': 'Unidades Completadas',
    Units: 'Unidades',
    'Loading...': 'Cargando...',
    'A problem occurred loading the course details':
      'Hubo un problema cargando los detalles del curso',
    'Click here to get back to your course.': 'Haz clic aquí para volver al curso.',
    PROGRESS: 'Progreso',
    COMPLETED: 'COMPLETADO',
    'Switch Course Start': '¿Estás seguro de que quieres comenzar?',
    YES: 'Sí',
    NO: 'No'
  },
  RecursiveOptionsList: {
    'Learning Programs': 'Programas de aprendizaje'
  },

  LessonsList: {
    START: 'COMENZAR',
    Continue: 'Continuar',
    PROGRESS: 'PROGRESO',
    COMPLETED: 'COMPLETADO',
    YES: 'Sí',
    NO: 'NO',
    'Are you sure you want to start the unit:': '¿Estás seguro/a que deseas estudiar la unidad:',
    Unit: 'Unidad',
    Lesson: 'Lección',
    NEW: 'NUEVO'
  },

  LanguageSelector: {
    LANGUAGE: 'Idioma'
  },
  BeginPasswordReset: {
    'Reset your password': 'Cambiar tu contraseña',
    Continue: 'Continuar',
    'Enter your cell phone number': 'Número de teléfono celular',
    'Enter your cell phone number. We will send you a text message with a verification code.':
      'Ingresa tu número de teléfono celular. Lo necesitamos para enviarte un código de verificación.',
    'Enter your phone number': 'Ingresa tu teléfono'
  },
  ConfirmPasswordReset: {
    'Confirmation in progress...': 'Confirmación en progreso...',
    'Enter the code': 'Ingresa el código:',
    'We just sent the text code to:': 'Te acabamos de enviar el código al:',
    'I need a new code': 'Necesito un código nuevo',
    'I need to change my cell phone number': 'Necesito cambiar mi número de teléfono',
    "Let's verify your phone": 'Vamos a verificar tu teléfono',
    'Enter different mobile number': 'Ingresar un número de teléfono diferente'
  },
  SetNewPassword: {
    'Please, complete your password': 'Por favor, completa tu contraseña',
    'Create New Password': 'Crear nueva contraseña',
    'Reset Password': 'Restablecer la contraseña',
    'Determine password rules': 'La constraseña debe tener al menos 6 caracteres.'
  },
  ResetPassword: {
    // Enter phone number
    'Reset your password': 'Cambiar tu contraseña',
    'Enter your cell phone number. We will send you a text message with a verification code.':
      'Ingresa tu número de teléfono celular. Lo necesitamos para enviarte un código de verificación.',
    Continue: 'Continuar',
    'Please, complete your phone number': 'Por favor, completa tu número de teléfono',
    'Enter your cell phone number': 'Número de teléfono celular',
    Step: 'Paso',
    of: 'de',
    // Enter code
    'Confirmation in progress...': 'Confirmación en progreso...',
    'Enter the code': 'Ingresa el código:',
    'Check your text message. We just sent you a 5-digit verification code to the number listed above.':
      'Te enviamos un mensaje de texto con un código de verificación temporal de 5 dígitos.',
    'I need a new code': 'Necesito un código nuevo',
    'I need to change my cell phone number': 'Necesito cambiar mi número de teléfono',
    "Let's verify your phone": 'Vamos a verificar tu teléfono',
    'Enter different mobile number': 'Usar un número de teléfono diferente'
  },
  Reconnect: {
    'Trying to reconnect in': 'Tratando de reconectarse en',
    'Reconnecting...': 'Conectándose...'
  },
  EnterPhoneComponent: {
    Welcome: 'Bienvenido',
    Continue: 'Continuar',
    'Cell phone number': 'Número de teléfono celular',
    'Please enter your cell phone number below.': 'Por favor ingresa debajo tu número de celular.',
    'Please enter your cell phone number': 'Por favor ingresa tu número de teléfono celular',
    'Please enter a valid phone number': 'Por favor ingresa un número de teléfono válido',
    'Enter your cell phone number': 'Número de teléfono celular',
    'You are part of': 'Estás en',
    'If not, please click here': (
      <span>
        Si no es así, por favor haz<u>click aquí</u>
      </span>
    )
  },
  NewLearnerComponent: {
    'Create your profile': 'Configura tu perfil',
    'Enter your PIN code': 'Ingresa tu código PIN',
    Continue: 'Continuar',
    'Allow my coach to send me messages': 'Permito recibir mensajes de mi coach',
    'By clicking Continue, you agree to our ': 'Al hacer clic en Continuar, aceptas nuestros ',
    'Terms of Service': 'Términos de servicio',
    'Privacy Policy': 'Políticas de Privacidad',
    'Please fill in all fields': 'Llena todos los campos por favor',
    'Create a new password. It needs to be 6 characters or more.':
      'Crea una nueva contraseña. Debe tener 6 caracteres o más.',
    'The PIN code is a 4+ digit code.': 'El código de PIN es un número de 4+ dígitos.',
    'Contact your organization if you did not receive one.':
      'Consulta con tu organización si no lo has recibido.',
    'Requesting access': 'Accediendo',
    'Pincode is Required': 'El codigo PIN es requerido',
    'PIN code does not exist': 'El código PIN suministrado no existe',
    'Enter your 4+ digit PIN code. This code comes from your organization.':
      'Ingresa tu código PIN de 4+ dígitos. Este código proviene de tu organización.',
    Step: 'Paso',
    of: 'de'
  },
  EnterPasswordComponent: {
    Password: 'Contraseña',
    'Log In': 'Iniciar Sesión',
    'Enter your password': 'Ingresa tu contraseña',
    'Forgot password?': '¿Olvidaste tu contraseña?',
    PasswordHint: 'La contraseña ingresada cuando se creó tu cuenta',
    PasswordFieldRequired: 'Por favor ingresa tu contraseña',
    'Welcome back!': 'Bienvenido de nuevo',
    'Please enter your password to access the app.':
      'Ingresa tu contraseña para entrar a la aplicación.',
    show: 'show',
    hide: 'hide',
    password: 'contraseña',
    'Invalid password, please try again.': 'Contraseña incorrecta, por favor intenta nuevamente.'
  },
  CreatePasswordComponent: {
    'Create new password': 'Crear nueva contraseña',
    Password: 'Crear Nueva Contraseña',
    Continue: 'Continuar',
    'Allow my coach to send me messages': 'Permito recibir mensajes de mi instructor',
    'By clicking Continue, you agree to our ': 'Al hacer clic en Continuar, aceptas nuestros ',
    'Terms of Service': 'Términos de servicio',
    PasswordHint: 'La contraseña debe tener 6 caractres o más.'
  },
  PlayerControls: {
    'Previous Lesson': 'Lección anterior',
    'Next Lesson': 'Lección siguiente',
    'Repeat previous lesson': 'Repetir lección anterior',
    'Skip to next lesson': 'Continuar a siguiente lección'
  },
  ReactImg: {
    '* Could not download. Tap to try again.':
      '* No se pudo descargar. Presiona aquí para volver a intentarlo.'
  },
  ReactCertificate: {
    error: 'No se pudo crear el certificado. Presiona aquí para volver a intentarlo.',
    loading: '¡Estamos creando tu certificado!',
    successText: '¡Felicitaciones!'
  },
  Certificate: {
    fetchCertificate: 'Buscando tu certificado...',
    retry: 'No pudimos encontrar tu certificado. Haz clic para volver a intentarlo.',
    downloadingCertificate: 'Preparando tu certificado para descargarlo...'
  },
  CoachContactPhone: {
    'You can also contact your coach at': 'También puedes contactar a tu coach al '
  },
  LoginFooter: {
    'I need help': 'Necesito ayuda'
  },
  FormPassword: {
    'Create a password': 'Crear Nueva Contraseña',
    'Confirm password': 'Confirmar contraseña',
    'Please enter in your new password': 'Por favor, crear nueva contraseña',
    'Password must be at least 6 letters and/or numbers':
      'La contraseña debe tener 6 letras y/o números',
    show: 'mostrar',
    hide: 'ocultar',
    password: 'contraseña',
    'Passwords do not match': 'Las contraseñas no coinciden'
  }
};
